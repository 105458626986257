import React from "react"
import { graphql } from "gatsby";
import AbstractLandingPage from "../../components/landing-page"
import AbstractLandingPageDe from "../../components/landing-page/de";
import landingImg from "../../media/landing-page/landing.jpg"
import {extractContentBetweenTags, splitStringBeforeTag} from "../../utils";
import {useI18next} from "gatsby-plugin-react-i18next";
import teamParallaxVideo from "../../media/videos/team_parallax.webm"
const DevopsLanding = ({data}) => {
	const {
		testimonials
	} = data
	const {
		language
	} = useI18next()
	const landingContent = {
		heading: "Für alle Führungskräfte, die",
		description: <h2>
			<span>die Automatisierungen ihrer technischen Infrastruktur erreichen wollen und diese robust und zukunftsorientiert aufbauen wollen, ohne:</span>
			<ul>
				<li>sich weiterhin auf veraltete und langsame Systeme zu verlassen und Zeit mit manueller Arbeit zu verschwenden</li>
				<li>im Wettbewerb auf der Strecke zu bleiben</li>
				<li>sich auf die langwierige Suche nach neue Fachkräfte zu begeben</li>
			</ul>
		</h2>,
		btn: {
			text: "Besprechung Vereinbaren",
			href: "#simplymeet",
		},
	}
    const products = data.products.edges
    const services = products.map(item => {
        return {
          img: item.node.frontmatter.smallImagePath.childImageSharp.fluid.src,
          title: item.node.frontmatter.title,
          description: item.node.frontmatter.summary,
          btn: {
              text: "read more",
              href: `/de${item.node.fields.slug}`
          }
        }
    })
	const testimonialPage = "devops"
	const testimonialData = testimonials.edges.filter(({node}) => node.fields.language === language && node.frontmatter.pages.includes(testimonialPage)).map((item) => {
		return {
			img: item.node.frontmatter.imagePath.childImageSharp.fluid,
			name: item.node.frontmatter.name,
			company: item.node.frontmatter.company,
			description: extractContentBetweenTags(splitStringBeforeTag(item.node.html, "h2").slice(-1)[0], "p"),
			href: item.node.frontmatter.href
		}
	})
	return (
		<AbstractLandingPageDe
			services={ services }
			landingImg={landingImg}
			landingContent={ landingContent }
			testimonials={ testimonialData }
			page={"devops"}
			video={teamParallaxVideo}
		/>
	)
}
export default DevopsLanding
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
            node {
                ns
                data
                language
            }
        }
    }
    products: allMarkdownRemark(
        limit: 3
        sort: { fields: [frontmatter___title], order: ASC }
        filter: {
            fields: {
                language: { eq: $language }
            }
            frontmatter: {
              isDraft: { ne: true }
              category: { eq: "portfolio" }
              isDevops: { eq: true }
            }
        }
    ) {
      edges {
        node {
          id
          frontmatter {
            summary
            title
            smallImagePath {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
      testimonials: allMarkdownRemark(
          filter: {
			fields: {
					language: { eq: $language }
				}
              frontmatter: {
                  category: {eq: "testimonial"}
                  publish: {eq: true }
              }
          }
      ) {
          edges {
              node {
                  html
                  fields {
                      language
                      slug
                  }
                  frontmatter {
                      name
                      company
                      href
                      pages
                      imagePath {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
              }
          }
      }
  }
`;
